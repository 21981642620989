.nav {
  padding: 0.6rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navImg {
  width: 22rem;
}

.navBtn {
  height: 2.5rem;
  margin-left: 1rem;
  transition: ease-in-out transform 300ms;
}

.navBtn:hover {
  transform: scale(110%);
}

@media (max-width: 38rem) {
  .nav {
    justify-content: center;
  }

  .navImg {
    width: 70vw;
  }

  .nav span {
    display: none;
  }
}
