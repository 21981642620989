.card {
  box-sizing: border-box;
  background: rgb(213, 217, 206);
  background: linear-gradient(
    0deg,
    rgba(213, 217, 206, 1) 0%,
    rgba(238, 239, 231, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
  border: white solid 0.3rem;
  margin: 0 0.5rem 1rem 0.5rem;
  padding: 0.8rem 0.3rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 0.4rem 1rem rgb(28, 28, 28);
}

.spellImg {
  width: 4.5rem;
  margin: 0 0.2rem;
  border-radius: 0.5rem;
  border: 0.15rem white solid;
}

.lvlBox {
  width: 1.3rem;
  border-radius: 0.3rem;
  display: inline-block;
  position: absolute;
  transform: translate(-4.85rem, 3.3rem);
  background-color: rgb(41, 47, 62);
  border: 0.12rem rgb(0, 0, 0) solid;
  font-size: 1rem;
  text-align: center;
  z-index: 2;
}

.lvlBoxMax {
  width: 1.3rem;
  border-radius: 0.3rem;
  display: inline-block;
  position: absolute;
  transform: translate(-4.9rem, 3.3rem);
  background-color: rgb(255, 179, 0);
  border: 0.12rem rgb(255, 225, 154) solid;
  font-size: 1rem;
  text-align: center;
  z-index: 2;
  color: black;
}

.ccOrOwn {
  position: absolute;
  transform: translate(0.34rem, -4.85rem);
  background-color: rgb(41, 47, 62);
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
  border: 0.12rem rgb(0, 0, 0) solid;
  font-size: 0.8rem;
}

.combos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0.5rem;
  padding-top: 1rem;
}

.greyOut {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 50%;
}

.noCombo {
  color: #5f5f5f;
  margin: 1rem 2rem;
}

.totalSpc {
  text-align: center;
  text-shadow: 0 0.4rem 0.6rem rgb(28, 28, 28);
}

@media (max-width: 45rem) {
  .combos {
    flex-direction: column;
  }
}
